<template>
  <div class="data-contain">
    <div class="card-wrapper">
      <div class="monitor-search">
        <Row :gutter="20">
          <Col :lg="{span: 8, offset: 4}" :md="{span: 12}">
            <Date-picker format="yyyy-MM-dd" v-model="time" type="daterange" placeholder="选择日期" @on-change="handleChangeDate"></Date-picker>
          </Col>
          <Col :lg="{span: 3}" :md="{span: 8}">
            <Select placeholder="请选择" label-in-value v-model="type" style="text-align: left;" @on-change="handleType">
              <Option v-for="item in typeData" :value="item.value" :key="item.value">{{ item.label }}</Option>
            </Select>
          </Col>
          <Col :lg="{span: 2}">
            <Button type="warning" @click="handleSearch">搜索</Button>
          </Col>
          <Col :lg="{span: 7}" :md="{span: 8}">
            <div class="tab-contain">
              <router-link class="static-button" :to="{name: 'BloodSugar'}" >统计显示</router-link>
              <router-link :to="{name: 'BloodSugarTable'}">数据显示</router-link>
              <router-link class="chart-button" :to="{name: 'BloodSugarChart'}">图表显示</router-link>
            </div>
          </Col>
        </Row>
      </div>
      <Row :gutter="40">
        <Col :lg="{span: 8}" :md="{span: 12}">
          <div class="card-module bg1">
            <Row>
              <Col :lg="{span: 12}">
                <Row type="flex" justify="center" align="middle">
                  <Col span="8"><img src="@/assets/img/icon-1.png" alt="" width="40px" height="40px"></Col>
                  <Col span="16">
                    <p :title="typeVal + '血糖最低：' + min_value + 'mmol/L'"><em class="font_30">{{min_value}}</em>mmol/L</p>
                    <h2 :title="typeVal + '血糖最低：' + min_value + 'mmol/L'">{{typeVal}}血糖最低</h2>
                  </Col>
                </Row>
              </Col>
              <Col :lg="{span: 12}">
                <Row type="flex" justify="center" align="middle">
                  <Col span="8"><img src="@/assets/img/icon-2.png" alt="" width="40px" height="40px"></Col>
                  <Col span="16">
                    <p :title="typeVal + '血糖最高：' + max_value + 'mmol/L'"><em class="font_30">{{max_value}}</em>mmol/L</p>
                    <h2 :title="typeVal + '血糖最高：' + max_value + 'mmol/L'">{{typeVal}}血糖最高</h2>
                  </Col>
                </Row>
              </Col>
            </Row>
          </div>
          <div class="card-footer bg1">
            <Row>
              <Col :lg="{span: 8, offset: 1}" :md="{span: 11, offset: 1}">低于标准：{{low_s}}次</Col>
              <Col :lg="{span: 8}" :md="{span: 11}">超出标准：{{high_s}}次</Col>
            </Row>
          </div>
        </Col>
        <Col :lg="{span: 8}" :md="{span: 12}">
          <div class="card-module-extra bg2">
            <h2 :title="typeVal + '血糖中位数：' + median + 'mmol/L'">{{typeVal}}血糖中位数 <em class="font_30 pl20">{{median}}</em>mmol/L</h2>
          </div>
        </Col>
        <Col :lg="{span: 8}" :md="{span: 12}">
          <div class="card-module-extra bg3">
            <h2 :title="typeVal + '血糖平均值：' + average + 'mmol/L'">{{typeVal}}血糖平均值 <em class="font_30 pl20">{{average}}</em>mmol/L</h2>
          </div>
        </Col>
      </Row>
    </div>
  </div>
</template>
<script type="text/ecmascript-6">
import MemberService from '@/services/memberService';
export default {
	data() {
		return {
			BloodSugar: {
				index: 0,
				type: '',
				start: '',
				end: '',
				page: 1,
				size: 10,
			},
			low_s: '',
			max_value: '',
			average: '',
			min_value: '',
			median: '',
			high_s: '',
			typeData: [],
			type: '',
			typeVal: '',
			value: '',
			time: [],
		};
	},
	computed: {
		memberId() {
			return this.$route.params.member_id;
		},
		firstDate() {
			let d = new Date();
			return `${d.getFullYear()}-${d.getMonth() + 1}-01`;
		},
		nowDate() {
			let d = new Date();
			let month = d.getMonth() + 1;
			month = month > 9 ? month : '0' + month;
			let day = d.getDate();
			day = day > 9 ? day : '0' + day;
			return `${d.getFullYear()}-${month}-${day}`;
		},
		last30Date() {
			// 获取当前日期
			var myDate = new Date();

			// 获取三十天前日期
			var lw = new Date(myDate - 1000 * 60 * 60 * 24 * 30);
			var lastY = lw.getFullYear();
			var lastM = lw.getMonth() + 1;
			var lastD = lw.getDate();
			var startdate =
				lastY + '-' + (lastM < 10 ? '0' + lastM : lastM) + '-' + (lastD < 10 ? '0' + lastD : lastD);

			return startdate;
		},
	},
	methods: {
		handleInit() {
			this.BloodSugar.member_id = this.memberId;
			MemberService.sugarQuery(this.BloodSugar).then((data) => {
				if (
					data.max_value === null ||
					data.min_value === null ||
					data.median === null ||
					data.average === null
				) {
					this.max_value = data.max_value;
					this.average = data.average;
					this.min_value = data.min_value;
					this.median = data.median;
				} else {
					this.max_value = data.max_value.toFixed(1);
					this.average = data.average.toFixed(1);
					this.min_value = data.min_value.toFixed(1);
					this.median = data.median.toFixed(1);
				}
				this.low_s = data.low_s;
				this.high_s = data.high_s;
			});
		},
		handleType(data) {
			this.BloodSugar.type = data.value;
			if (data.label === '') {
				return this.value;
			} else {
				this.value = data.label;
			}
		},
		handleChangeDate(value) {
			this.BloodSugar.start = value[0];
			this.BloodSugar.end = value[1];
			this.time = value;
		},
		handleSearch() {
			this.typeVal = this.value;
			this.handleInit();
		},
	},
	mounted() {
		MemberService.checkProject().then((data) => {
			this.typeData = data.project[1].children;
			this.type = this.BloodSugar.type = data.project[1].children[0].value;
			this.typeVal = this.value = data.project[1].children[0].label;
			this.time = [this.last30Date, this.nowDate];
			this.BloodSugar.start = this.last30Date;
			this.BloodSugar.end = this.nowDate;
			this.handleInit();
		});
	},
};
</script>
<style lang="css" scoped>
</style>
